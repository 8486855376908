<template>
  <div class="fullScreen" :class="{ show: true }" v-loading="loading">
    <Crumbs></Crumbs>
    <div class="dictMain">
      <div class="mainList box-shadow">
        <div class="mainList_operation clearfloat">
          <div class="mainList_operation_inline_block">
            <div class="title" style="padding-top: 5px">增值税类型</div>
          </div>
          <div class="fr">
            <el-button type="primary" icon="el-icon-plus" @click="add()">新增</el-button>
          </div>
        </div>
        <div class="mainList_content">
          <div class="mainList_content_sub">
            <el-table
              ref="multipleTable"
              border
              :data="tableData"
              tooltip-effect="dark"
              height="string"
              :highlight-current-row="false"
              :cell-class-name="setCellClass"
              @row-click="rowClick"
              v-loading="loading"
            >
              <el-table-column align="center" prop="vatName" label="类型名称"></el-table-column>
              <el-table-column label="操作" align="center" width="150">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    class="text_Edit_Bgc"
                    @click.stop="edit(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    class="text_Remove_Bgc"
                    size="mini"
                    @click.stop="del(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="pageChangeHandler"
              @size-change="handleSizeChange"
              :current-page="parameter.pageNow"
              :page-size="parameter.pageSize"
              prev-text="上一页"
              next-text="下一页"
              :total="parameter.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <Sub
        class="box-shadow"
        :data="activeTypeData"
        type="second"
        @clickDetail="handleDetailClick"
        @clearRate="handleClearRate"
      ></Sub>
      <Sub
        class="box-shadow"
        :data="activeDetailData"
        type="third"
        :typeName="activeTypeData.vatName"
        @clearRate="handleClearRate"
      ></Sub>
    </div>
    <Edit v-if="showEditDialog" :isShow.sync="showEditDialog" :options="options"></Edit>
  </div>
</template>

<script>
export default {
  components: {
    Edit: () => import('./Edit.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
    Sub: () => import('./Sub.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        dictType: '',
      },
      loading: false,
      showEditDialog: false, //弹框显示隐藏
      options: {}, //弹框内容传参
      tableData: [], //增值税类型数组
      activeTypeData: {}, //当前点击的类型内容
      activeDetailData: {}, //当前点击的明细内容
      highlightedCell: '',

      nextid: 5,
    }
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  watch: {
    tableData: {
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      this.$api.vat
        .getVatTypeList(this.parameter)
        .then(res => {
          this.loading = false
          this.tableData = res.data.records
          this.parameter.total = Number(res.data.total)
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },

    rowClick(row) {
      this.highlightedCell = { id: row.id }
      this.activeTypeData = row
      this.activeDetailData = {}
    },
    setCellClass({ row }) {
      const isHighlighted = this.highlightedCell && this.highlightedCell.id === row.id
      return isHighlighted ? ['highlight'] : []
    },
    // 子传父，拿到当前明细点击的税率数组
    handleDetailClick(row) {
      this.activeDetailData = row
    },
    add() {
      this.options = {}
      this.showEditDialog = true
    },
    edit(row) {
      this.options = row.deepClone()
      this.showEditDialog = true
    },
    del(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const isHighlighted = this.highlightedCell && this.highlightedCell.id === row.id
          // 如果当前高亮行被删除，则刷新子内容
          if (isHighlighted) {
            this.activeTypeData = {}
            this.activeDetailData = {}
          }

          this.$api.vat
            .deleteVatType(row.id)
            .then(res => {
              this.$message.success('删除成功！')
              var hasLastPage =
                this.parameter.pageSize * this.parameter.pageNow >= this.parameter.total
              var lastData = this.tableData.length < 2
              if (lastData && hasLastPage && this.parameter.pageNow > 1) {
                this.parameter.pageNow--
              }
              this.getData()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },

    refresh() {
      this.options = {}
      this.showEditDialog = false
      this.getData()
    },

    handleClearRate() {
      this.activeDetailData = {}
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/config.scss';
.dictMain {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 45px);
  padding: 20px;
  .mainList {
    background: #fff;
    border-radius: 8px;
    .mainList_operation {
      padding: 0;
      margin-bottom: 10px;
      .title {
        font-size: 20px;
        color: #303133;
      }
    }
    .mainList_content_sub {
      padding: 0;
    }
    &:first-child {
      width: 40%;
    }
    // &:last-child {
    //   width: 33%;
    // }
  }
}
::v-deep .highlight {
  background: rgb(241, 212, 158) !important;
}
</style>
